<template>
  <div class="dealer-alarm">
    <div class="dealer-alarm-filter">
      <el-tabs v-model="activeName">
        <el-tab-pane label="预警列表" name="alarm"></el-tab-pane>
        <el-tab-pane label="通知分组" name="notify"></el-tab-pane>
      </el-tabs>
    </div>

    <alarm-logs v-show="activeName ==='alarm'"></alarm-logs>
    <alarm-group v-show="activeName ==='notify'"></alarm-group>
  </div>
</template>

<script>
  import AlarmLogs from './logs'

  export default {
    name: '',
    components: {
      AlarmLogs,
      AlarmGroup: function(resolve) {
        require(["./group.vue"], resolve);
      },
    },
    mounted: function () {
    },
    data: function () {
      return {
        activeName: 'alarm',
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  .dealer-alarm {
    &-filter {
      height: 48px;
      margin-bottom: $padding;

      .el-tabs__item {
        @include set_font_color($--font-color, $--font-color-dark);
        height: 40px;
        line-height: 48px;
        font-weight: normal;
      }

      .el-tabs__nav-wrap::after {
        @include set_bg_color($background-color-light-2, $background-color-dark-1);
      }
    }

    .device-container {
      min-height: 558px;
      border-radius: $border-radius;
      @media (max-height: 768px) {
        min-height: 526px;
      }
    }
  }
</style>
