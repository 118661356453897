<template>
  <div class="dealer-alarm-history">
    <el-row>
      <el-form label-width="auto" :inline="true" size="mini">
        <el-col :span="6">
          <el-form-item label="预警类型:">
            <el-select placeholder="请选取预警类型" v-model="pagination.orderStyle" class="order-input-filter">
              <el-option v-for="item in alarmTypes"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="通知人:">
            <el-input placeholder="请输入通知人名称" class="order-input-filter" clearable
                      v-model="pagination.name"
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预警发生时间:">
            <el-date-picker
              v-model="makeTimeRange"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>

      </el-form>
    </el-row>
    <div class="dealer-alarm-history-list">
      <el-table :data="logs">
        <el-table-column label="预警编号" align="center" prop="month"></el-table-column>
        <el-table-column label="预警类型" align="center" prop="month"></el-table-column>
        <el-table-column label="预警起因" align="center" prop="cups"></el-table-column>
        <el-table-column label="预警时间" align="center" prop="freeCups"></el-table-column>
        <el-table-column label="通知人" align="center" prop="sellerCups"></el-table-column>
        <el-table-column label="通知时间" align="center" prop="sellerCups"></el-table-column>
        <el-table-column label="处理时间" align="center" prop="rent"></el-table-column>
        <el-table-column label="处理时长" align="center" prop="seller"></el-table-column>
        <el-table-column label="预警备注" align="center" prop="seller"></el-table-column>
        <el-table-column label="操作" align="center" prop="seller">
          <template slot-scope="scope">
            <span>查看更多</span>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <el-pagination
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        pagination: {
          startAt: 0,
          endAt: 0,
          orderType: 0,
          payStyle: 0,
          total: 0,
          name: '',
          orderUuid: '',
          current: 0,
          start: 0,
          limit: 10,
        },
        makeTimeRange: [],
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0, 0, 0, 0)
              end.setHours(23, 59, 59, 0)
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              start.setHours(0, 0, 0, 0)
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              end.setHours(23, 59, 59, 0)

              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        logs: [],
        alarmTypes: [
          {label: "停机预警", value: 1},
          {label: "期望预警", value: 2},
          {label: "余量预警", value: 3},
          {label: "故障预警", value: 4},
          {label: "异常预警", value: 5},
          ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss">
  .dealer-alarm-history {
  }
</style>
